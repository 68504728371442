import DataLayerScope from 'swp-components/lib/components/common/data-layer-scope'
import Footer from 'swp-components/lib/components/footer'
import Section from 'swp-components/lib/components/section'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import BottomBanner from '@/components/bottom-banner'
import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Faq from '@/components/faq'
import Header from '@/components/header'
import MobilePackage from '@/components/mobile-package'
import Newrelic from '@/components/newrelic-agent'
import PrivilegeShelf from '@/components/privilege-shelf'
import SectionHeading from '@/components/section-heading'
import Seo from '@/components/seo'
import ShortcutMenu from '@/components/shortcut-menu'
import TopBanner from '@/components/top-banner'
import useTrueMoveHPostpaidPackageTypePage from '@/hooks/data-layers/truemoveh/use-truemoveh-postpaid-package-type-page'
import useMobilePackage from '@/hooks/use-mobile-package'
import NotFoundPage from '@/pages/404'
import strapi from '@/strapi'

const TrueMoveHPostpaidPackageTypePage = ({ serverData }) => {
  let [
    packageType,
    headerScript,
    pageHeaders,
    brandHeaders,
    productServices,
    shortcutMenus,
    postpaidPackages,
    faq,
    footerHeaders,
    chatShop,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)

  let mobilePostpaidPackages = useMobilePackage('tmh-package-type-postpaid', {
    postpaidPackages,
  })

  if (!packageType) {
    return <NotFoundPage />
  }

  let { handleMessage, createScopeProps } =
    useTrueMoveHPostpaidPackageTypePage()

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={packageType.packagesSeo} script={headerScript.script} />
        <DataLayerScope {...createScopeProps('Header')}>
          <Header items={brandHeaders} services={productServices} />
        </DataLayerScope>
        {packageType.topBanners.length > 0 && (
          <DataLayerScope
            {...createScopeProps('TopBanner', {
              topBanners: packageType.topBanners,
            })}
          >
            <TopBanner banners={packageType.topBanners} />
          </DataLayerScope>
        )}
        {shortcutMenus.length > 0 && (
          <Section.Background className="py-4" color="light-orange">
            <DataLayerScope {...createScopeProps('ShortcutMenu')}>
              <ShortcutMenu color="orange" items={shortcutMenus} />
            </DataLayerScope>
          </Section.Background>
        )}
        {packageType && (
          <div className="my-5">
            <SectionHeading variant="underline" tag="h1">
              {packageType.name}
            </SectionHeading>
            <SectionHeading className="mt-3 mb-4" level="2">
              {packageType.detail}
            </SectionHeading>
            {packageType.privileges.length > 0 && (
              <>
                {packageType.privilegeHeader && (
                  <SectionHeading
                    className="mb-4"
                    variant="button-line-through"
                  >
                    {packageType.privilegeHeader}
                  </SectionHeading>
                )}
                <DataLayerScope
                  {...createScopeProps('PrivilegeShelf', {
                    privileges: packageType.privileges,
                  })}
                >
                  <PrivilegeShelf privileges={packageType.privileges} />
                </DataLayerScope>
              </>
            )}
          </div>
        )}
        {mobilePostpaidPackages.map(section => (
          <div className="my-5" key={section.id}>
            <SectionHeading variant="underline" tag="h2">
              {section.title}
            </SectionHeading>
            <SectionHeading className="mt-3 mb-4" level="2">
              {section.subtitle}
            </SectionHeading>
            <DataLayerScope
              {...createScopeProps('MobilePackage', {
                packages: section.packages,
                header: section,
              })}
            >
              <MobilePackage
                variant="extended-card"
                packages={section.packages}
              />
            </DataLayerScope>
          </div>
        ))}
        {packageType.bottomBanner && (
          <DataLayerScope {...createScopeProps('BottomBanner')}>
            <BottomBanner banners={[packageType.bottomBanner]} />
          </DataLayerScope>
        )}
        {faq && (
          <Section.Background className="py-5" color="orange" circle>
            {pageHeaders.tmhPostpaidPage.faqHeader && (
              <>
                <SectionHeading color="white" tag="h2">
                  {pageHeaders.tmhPostpaidPage.faqHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" color="white" level="2">
                  {pageHeaders.tmhPostpaidPage.faqHeader.detail}
                </SectionHeading>
              </>
            )}
            <DataLayerScope
              {...createScopeProps('Faq', {
                ...faq,
                header: pageHeaders.tmhPostpaidPage.faqHeader,
              })}
            >
              <Faq color="orange" topics={faq.faqs} viewAll={faq.viewAll} />
            </DataLayerScope>
          </Section.Background>
        )}
        {footerHeaders && (
          <DataLayerScope {...createScopeProps('Footer')}>
            <Footer className="pb-4" items={footerHeaders} />
          </DataLayerScope>
        )}
        {chatShop && (
          <DataLayerScope {...createScopeProps('ChatShop')}>
            <ChatShop
              content={chatShop}
              mariPlatform={ serverData.locale == 'th' ? "mari_tmh" : "truemoveh" }
              mariConfig={mariConfig}
            />
          </DataLayerScope>
        )}
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default TrueMoveHPostpaidPackageTypePage

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents(
    [
      `tmh-postpaid-package-types/${params.slug}`,
      'header-script',
      'header-page',
      'brand-headers',
      'product-services',
      'tmh-postpaid-shortcut-menus',
      `tmh-postpaid-package-types/${params.slug}/packages`,
      'tmh-postpaid-faq',
      'footer-headers',
      'chat-and-shop',
      'mari-chat-and-shop-config',
    ],
    pageContext.locale
  )

  return {
    props: { contents, locale: pageContext.locale },
  }
}
