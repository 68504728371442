import { FormattedMessage } from 'react-intl'
import { Container } from 'swp-components/lib/components/bootstrap'
import { Button, DataLayer } from 'swp-components/lib/components/common'
import { Card } from 'swp-components/lib/components/faq'

import generateSliderOptions from '@/util/generate-slider-options'

import Slider from '../slider'
import style from './style'

const Faq = ({ color, topics, viewAll, ...props }) => {
  let sliderOptions = generateSliderOptions(280)

  return (
    <div {...props}>
      <Container className="position-relative">
        <Slider color="white" options={sliderOptions}>
          {topics.map(topic => (
            <div key={topic.id} css={style.box}>
              <Card color={color} topic={topic} />
            </div>
          ))}
        </Slider>
      </Container>
      {viewAll && (
        <Container className="d-flex justify-content-center mt-3">
          <DataLayer
            selector="Faq.viewAllButton"
            triggers={['click']}
            render={ref => (
              <Button
                ref={ref}
                color="white"
                variant="outlined"
                link={viewAll.link}
              >
                <FormattedMessage id="viewAll" defaultMessage="View All" />
              </Button>
            )}
          />
        </Container>
      )}
    </div>
  )
}

export default Faq
